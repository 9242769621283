<template>
  <v-container> dashboard </v-container>
</template>


<script>
export default {
  computed: {},

  data: () => ({}),

  watch: {},

  methods: {},
};
</script>